import React from "react";
import { Container, Row, Col, Collapse, CardHeader, CardBody, Card } from "reactstrap";

class Faq extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openCard: "",
        };

        this.toggleCollapse = this.toggleCollapse.bind(this);
    }

    toggleCollapse(cardNumber) {
        this.setState((prevState, props) => {
            if (prevState.openCard !== cardNumber) {
                return { openCard: cardNumber };
            }
            return { openCard: "" };
        });
    }

    render() {
        return (
            <Container className="mt-5">
                <Row>
                    <Col className="text-center">
                        <h2 className="display-3" id="faq">
                            FAQ
                        </h2>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                        <CardHeader onClick={() => this.toggleCollapse("1")} className="no-select pointer">
                            <span className="faq-number-badge">1</span>How much does Havuh cost?
                        </CardHeader>
                        <Collapse isOpen={this.state.openCard === "1"}>
                            <Card>
                                <CardBody>
                                    Havuh is free-to-use. There is no cost to download the app and there are no annual
                                    or monthly fees. The only time money comes into the picture is when a job closes and
                                    a vendor compensates the agent.
                                </CardBody>
                            </Card>
                        </Collapse>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                        <CardHeader onClick={() => this.toggleCollapse("2")} className="no-select pointer">
                            <span className="faq-number-badge">2</span>How do agents make money?
                        </CardHeader>
                        <Collapse isOpen={this.state.openCard === "2"}>
                            <Card>
                                <CardBody>
                                    Vendors pay the agent who originates the project through the app when vendors
                                    complete the work the agent's been managing.
                                </CardBody>
                            </Card>
                        </Collapse>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                        <CardHeader onClick={() => this.toggleCollapse("3")} className="no-select pointer">
                            <span className="faq-number-badge">3</span>How much can I expect to make?
                        </CardHeader>
                        <Collapse isOpen={this.state.openCard === "3"}>
                            <Card>
                                <CardBody>
                                    <p>
                                        As an agent, this question depends on a lot of factors: how big your network is,
                                        how long you've been in the industry, how often your clients ask for your help
                                        on home repairs and renovations.
                                    </p>
                                    <p>
                                        As a brokerage, the same factors apply but revenue will also depend on how many
                                        agents you have in your offices and of those agents, how many use Havuh.
                                    </p>
                                    <p>
                                        That being said, we built a calculator to help agents and brokerages estimate
                                        earnings. You can check it out{" "}
                                        <a style={{ fontWeight: 500 }} href="https://havuh.com/earnings-calculator">
                                            here
                                        </a>
                                        .
                                    </p>
                                </CardBody>
                            </Card>
                        </Collapse>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                        <CardHeader onClick={() => this.toggleCollapse("4")} className="no-select pointer">
                            <span className="faq-number-badge">4</span>What about my vendor list?
                        </CardHeader>
                        <Collapse isOpen={this.state.openCard === "4"}>
                            <Card>
                                <CardBody>
                                    <p>
                                        That vendor list you used to send to your clients, get those vendors on the app.
                                        Vendor lists are great but who do clients call when the vendor is late? Who does
                                        the vendor call if they can't get in the house? They call you. So onboard your
                                        vendors to Havuh and get compensated for going above and beyond as a real estate
                                        agent.
                                    </p>
                                </CardBody>
                            </Card>
                        </Collapse>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                        <CardHeader onClick={() => this.toggleCollapse("5")} className="no-select pointer">
                            <span className="faq-number-badge">5</span>What about RESPA?
                        </CardHeader>
                        <Collapse isOpen={this.state.openCard === "5"}>
                            <Card>
                                <CardBody>
                                    <p>
                                        Havuh enables agents to be compensated by vendors for the significant amount of
                                        time agents spend acting as project managers on behalf of their clients and
                                        vendors. Acting in this role is outside of an agents traditional duties, takes a
                                        lot of their time and effort, and isn't already compensated.
                                    </p>
                                    <p>
                                        Agents aren't just connecting their clients to vendors. They become the point of
                                        contact for both parties, assisting throughout the entire project. Agents find
                                        themselves coordinating vendor arrivals, unlocking the house for them, handling
                                        materials deliveries, reviewing quotes with clients, and a lot more.
                                    </p>
                                    <p>
                                        As a really simple example, one of our agents told us a story about a client who
                                        had a leak in her basement. The agent reviewed multiple photos to try to figure
                                        out the source of the leak. They called a trusted plumber to explain what was
                                        happening. The next day when the plumber was scheduled to show up at the
                                        client's house, the plumber was late and the client couldn't get ahold of them.
                                        The client called the agent. The agent called the plumber. The agent called the
                                        client back to tell them the plumber wouldn't make it but they could come the
                                        follwing day. When the work was finished, the client had questions about the
                                        work the plumber had done. So they called the agent. All told, the agent
                                        estimated they exchanged over 20 calls and 175 text messages.
                                    </p>
                                </CardBody>
                            </Card>
                        </Collapse>
                    </Col>
                </Row>
                <Row>
                    <Col lg={{ size: 10, offset: 1 }}>
                        <CardHeader onClick={() => this.toggleCollapse("6")} className="no-select pointer">
                            <span className="faq-number-badge">6</span>What about my clients?
                        </CardHeader>
                        <Collapse isOpen={this.state.openCard === "6"}>
                            <Card>
                                <CardBody>
                                    <p>
                                        Your clients will continue to benefit for your efforts as they always have, at
                                        no cost to them. You keep doing what makes you an agent that clients want to
                                        tell their friends and family about and let vendors compensate you for all of
                                        the work that you put in on their behalf.
                                    </p>
                                </CardBody>
                            </Card>
                        </Collapse>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default Faq;
