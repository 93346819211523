/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter";

class TermsAndConditions extends React.Component {
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    render() {
        return (
            <>
                <DemoNavbar />
                <main ref="main">
                    <section className="section section-shaped section-lg">
                        <div className="shape shape-style-1 bg-gradient-default">
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                        </div>
                        <Container className="pt-lg-7">
                            <Row className="justify-content-center">
                                <h1 className="text-white">Terms & Conditions</h1>
                            </Row>
                        </Container>
                    </section>
                    <section>
                        <Container className="pt-lg-7 mt-3">
                            <Row className="justify-content-center">
                                <Col lg="12">
                                    <section>
                                        <p>
                                            Thank you for considering Havuh.com, a digital platform ("Website") owned
                                            and managed by Havuh LLC ("Havuh," "we," or "us"). Havuh provides users
                                            (collectively referred to as "users," and individually as "user," "Client,"
                                            "Vendor," "Service Provider," or "you") a convenient online platform to
                                            locate service providers (collectively "Service Providers"), request quotes
                                            from Service Providers, manage projects to Service Providers, and receive
                                            compensation from Service Providers (collectively "Service Providers"). By
                                            signing up on the Website or Mobile Application, you become a user at no
                                            cost and have the opportunity to seek service and provide from vendors and
                                            users. The term "Website" applies broadly to all Havuh.com usage across
                                            multiple platforms, including, but not limited to, mobile applications,
                                            personal computers, mobile devices, email, phones, or other electronic
                                            devices.
                                        </p>
                                    </section>
                                    <section>
                                        <h3>1. Acknowledgment and Agreement</h3>
                                        <p>
                                            1.1 Your Unconditional Agreement: This Website and Havuh’s services are
                                            exclusively provided for use within the United States, and your acceptance
                                            of these services is bound unconditionally and without alteration to the
                                            terms, conditions, and notices ("Terms") contained in this Havuh Terms of
                                            Service ("Agreement"). We urge you to read this Agreement thoroughly.
                                            Whether or not you register with the app / website, your utilization of the
                                            app and any associated services implies your agreement to all Terms.
                                            Understand that specific features of the app may be governed by additional
                                            terms and conditions or registration requirements. You agree to comply with
                                            these supplementary terms, and accept that any violation of these terms
                                            while using the app will be regarded as a breach of this Agreement. This
                                            Agreement also encompasses Havuh’s Privacy Policy and any notices regarding
                                            the app.
                                        </p>
                                        <p>
                                            1.2 Information Sharing: By using the Havuh application and providing
                                            information within it, the project manager (user that creates the project,
                                            agent) explicitly gives their consent to share that information with vendors
                                            and Havuh LLC. This information may include but is not limited to the
                                            contact details, projects created, and other relevant information necessary
                                            for the project request process. The project manager acknowledges that this
                                            information will be used by Havuh LLC and shared with the vendors for the
                                            purpose of facilitating the project and providing the associated services.
                                        </p>
                                        <p>
                                            1.3 Confidentiality and Data Protection: Havuh LLC takes the privacy and
                                            protection of personal information seriously. All information shared within
                                            the application will be handled in accordance with Havuh's Privacy Policy
                                            and applicable data protection laws. Havuh LLC will implement reasonable
                                            measures to safeguard the confidentiality and security of the information
                                            provided. However, the user acknowledges that no method of transmission over
                                            the internet or electronic storage is completely secure, and Havuh LLC
                                            cannot guarantee the absolute security of any information shared within the
                                            application.
                                        </p>
                                        <p>
                                            1.4 Compliance with Laws: The user agrees to comply with all applicable laws
                                            and regulations while using the Havuh application and its services. This
                                            includes but is not limited to data protection laws, RESPA, anti-spam laws,
                                            and any other relevant legislation governing the sharing of personal
                                            information.
                                        </p>
                                        <p>
                                            By accepting the Havuh Terms of Service Agreement, the user acknowledges and
                                            agrees to the terms outlined above regarding information sharing,
                                            confidentiality, and compliance with laws. It is important for the user to
                                            review the Agreement, including the Privacy Policy, and fully understand
                                            their rights and responsibilities before using the Havuh application.
                                        </p>
                                        <p>
                                            1.5 Amendments: By using Havuh, you acknowledge and agree that Havuh can
                                            modify this Agreement at any point by uploading a revised version of the
                                            Agreement on the Website or by directly notifying users at Havuh's sole
                                            discretion. Your ongoing use of the Website after any alterations are made
                                            to this Agreement signifies your acceptance of such changes. Havuh does not
                                            and will not undertake any obligation to inform users of any changes to this
                                            Agreement or the creation or modification of any additional terms. It is
                                            your responsibility to stay updated with the most current version of the
                                            Terms of the Agreement.
                                        </p>
                                    </section>
                                    <section>
                                        <h3>2. Use and Age Restrictions. </h3>
                                        <p>
                                            The Website usage is only allowed to individuals who can form legally
                                            binding contracts under applicable law. To be more specific, the Website is
                                            not available to those under the age of 18. If you do not qualify, kindly
                                            refrain from using the Website. When using the Website, you confirm and
                                            agree that (a) you are at least 18 years old, are legally eligible to form a
                                            binding contract with Havuh, and are otherwise capable of entering into
                                            binding contracts; and (b) if you are utilizing the Website on behalf of a
                                            company, entity, or other organization, that you are an authorized
                                            representative of the entity with the authority to and agree to abide by
                                            this Agreement.
                                        </p>
                                    </section>
                                    <section>
                                        <h3>3. Professional Project Management Services</h3>
                                        <p>
                                            3.1 Project Requests. Following registration with Havuh, a user can submit a
                                            request via the Website for a vendor to reach out to their client ("Project
                                            Request") regarding desired services or tasks. Users must first create a
                                            Havuh account before making a Project Request. To submit a valid Project
                                            Request, users need to provide the necessary information (e.g., the services
                                            required by the client, the nature of the services/tasks, username, number,
                                            address). Upon creating a Project Request, Havuh allows users to select
                                            vendors from its database who are located near the user, or onboard their
                                            own vendor. The user can then choose one or more vendors to receive their
                                            project request. The chosen vendor(s) will then get in touch with the user
                                            to discuss the user's clients service requirements. Whenever a vendor
                                            communicates with a user or client, they do so independently and not as a
                                            representative or agent of Havuh, or any of their affiliates. Havuh neither
                                            guarantees the availability of vendors willing or capable of fulfilling the
                                            requested services at the desired time and place, nor that vendors will
                                            reach out to the user or client. All Project Requests must be legal and not
                                            violate any municipal or county ordinance, state, local or federal laws. If
                                            the user requires additional or future services that are outside the scope
                                            of work specified in their original user Request, the user should return to
                                            Havuh for additional project management services for 1 calendar year after
                                            the initial request for services.  If any additional services with the
                                            mutual parties are completed, it is agreed that the project manager (user,
                                            agent) is to be compensated as per the original Project Request. The
                                            vendor(s) suggested by Havuh are not only for the work scope mentioned in
                                            the user’s Request.
                                        </p>
                                    </section>
                                    <section>
                                        <h3>4. Vendors</h3>
                                        <p>
                                            4.1 Background Verification: It should be noted that Havuh does not conduct
                                            any background checks on the vendors principal/owner named during the
                                            vendor's registration or at any subsequent time. Havuh does not carry out
                                            criminal background checks, sex offender searches, or social security
                                            verification for the principal/owner of the vendor's business. Consequently,
                                            Havuh cannot provide any warranties or assurances about the currentness or
                                            validity of any such information. Therefore, the responsibility of carrying
                                            out background checks and ensuring the credibility of the vendor falls on
                                            the agent (the person creating and managing the project) and the client (the
                                            owner of the job). It's up to these parties to establish if the vendor (the
                                            individual receiving the project request) passes their background check
                                            requirements. 
                                        </p>
                                        <p>
                                            4.2 Licensing Information: Vendors are encouraged to include their licensing
                                            details in their resume’ which may be requested at any time outside of the
                                            app by any party. Depending on the jurisdiction, certain services, work,
                                            and/or project amounts may necessitate state and/or local (county or
                                            municipal) level licensing. The term "Vendors" should not be interpreted as
                                            a guarantee by Havuh that all vendors are appropriately licensed. The
                                            responsibility for determining the necessary licenses for the desired
                                            services and for verifying whether the chosen vendor possesses these
                                            licenses rests solely with the agent and client. Before embarking on a
                                            project, it is advisable to verify all licensing prerequisites with your
                                            state and local authorities and your chosen vendor. Havuh highly recommends
                                            asking your vendor to provide a copy of their license(s).
                                        </p>
                                        <p>
                                            4.3 Insurance and Bonding: Vendors should have copies of information and
                                            documents related to insurance policies and/or bonds that cover their
                                            services. Havuh does not verify this information. Before commencing a
                                            project, the client and agent should confirm the vendor's current insurance
                                            coverages, limits, and whether they hold any bonds.
                                        </p>
                                        <p>
                                            4.4 Relationship between Havuh, Vendor and You: The relationship between
                                            Havuh and the vendor is separate and independent. An employee of one entity
                                            does not equate to an employee of another entity. Havuh is not a
                                            representative of the vendor, and the vendor is not a representative of
                                            Havuh. Also, Havuh does not act as your agent. Under no circumstances will
                                            Havuh be considered a general contractor, specialty contractor, construction
                                            manager, project manager, joint employer, joint venture, or partner of you,
                                            any vendor or its affiliates or their respective personnel.
                                        </p>
                                        <p>
                                            4.5 Client and Agent Verification Responsibility: Havuh does not verify all
                                            information or documents provided by vendors and expressly disclaims any
                                            guarantees, indemnities, or warranties concerning such information. Clients
                                            and agents should conduct their own investigation of vendors to investigate
                                            and determine, among other things, whether vendors are appropriately
                                            licensed for the requested services, free of significant federal, state, or
                                            local-level civil legal judgments, and carry appropriate insurance or
                                            bonding at appropriate coverages and limits.
                                        </p>
                                        <p>
                                            4.6 No Warranty or Endorsement of Vendor Services. Havuh does not endorse or
                                            recommend the services of any vendor. The decision to enter into an
                                            agreement for work is entirely up to the agent and client. Havuh does not
                                            warrant the vendor's performance on the job or the quality of goods,
                                            materials, equipment, or services provided by the vendor. Havuh does not
                                            contract for or perform and is not responsible for any of the services
                                            requested through the platform. You expressly acknowledge and agree that
                                            your use of the Havuh app is at your sole risk. Havuh makes no
                                            representations or warranties as to the conduct of any vendor or the
                                            vendor's ability to provide a service that complies with the agent's and
                                            client's requirements. You expressly acknowledge that Havuh makes no
                                            warranties, either express or implied, regarding the quality of services
                                            rendered by any service provider.
                                        </p>
                                    </section>
                                    <section>
                                        <h3>5. Payments to Vendors:</h3>
                                        <p>
                                            5.1 The client is responsible for paying the vendor directly for any work
                                            completed as per their agreement. The client should arrange payment
                                            arrangements directly with the vendor. In certain cases, the vendor may
                                            require the client to pay a deposit before commencing the work. By accepting
                                            the project management proposal or client, the vendor agrees to pay the full
                                            management fee to the agent through the application upon project completion.
                                        </p>
                                        <p>
                                            5.2 Breach of Terms and Conditions: Vendors and agents must adhere to the
                                            terms and conditions of the application. It is explicitly stated that if the
                                            vendor and agent agree to payment outside of the application, they will be
                                            in breach of the terms and conditions. In such cases, Havuh reserves the
                                            right to take legal action to the fullest extent permitted by law.
                                        </p>
                                        <p>
                                            5.3 Project Validity: Any accepted project involving an agent, vendor, and
                                            client will remain valid for a period of 12 months, unless otherwise
                                            specified and documented. This means that any subsequent work carried out
                                            within this partnership will still require a management fee through the
                                            application.
                                        </p>
                                        <p>
                                            5.4 Scope of Project Management: The role and responsibility of the project
                                            management is to be determined outside of the app between the vendor,
                                            client, and agent.  Havuh does not make any guarantees, warranties, or
                                            promises on what the project management position may entail.  Once the
                                            management fee is agreed upon, it is not to change regardless of the
                                            performance of any party.
                                        </p>
                                        <p>
                                            5.5 Liability: Havuh will not be held liable for any claims made by the
                                            client regarding charges to their Havuh account, which are authorized by the
                                            client's agreement with the vendor. Any relationships between vendors,
                                            clients, and agents noted within the application do not make Havuh
                                            responsible for any disputes or issues that may arise between the parties.
                                        </p>
                                        <p>
                                            It is important for vendors, agents, and clients to understand and comply
                                            with these payment and project management policies outlined by Havuh to
                                            ensure a fair and transparent process for all parties involved.
                                        </p>
                                    </section>
                                    <section>
                                        <h3>6. Project Management Service Guarantee:</h3>
                                        <p>
                                            6.1 Havuh does not and cannot guarantee the work, materials supplied, or
                                            services rendered by the vendor. Havuh has no obligation or relationship
                                            whatsoever to the agent or client with regard to the provision of work,
                                            material, or services by the vendor. Havuh offers no guarantee and does not
                                            cover faulty workmanship of the vendor or damages to your product or
                                            property that arise from or relate to the services of the vendor.
                                        </p>
                                    </section>
                                    <section>
                                        <h3>7. Interactions and Disputes with Vendors:</h3>
                                        <p>
                                            7.1 Disputes with Vendors: Havuh has no direct involvement in resolving any
                                            disputes between users, including Cleints and Vendors. Havuh is not
                                            responsible for any disputes, claims, losses, injuries, or damages that may
                                            arise from user interactions or conduct.
                                        </p>
                                        <p>
                                            7.2 Relationship Between Clients and Vendors: Clients bear sole
                                            responsibility for their interactions with Vendors, including the terms and
                                            conditions of any contracts they enter into. The rights of the Clients under
                                            contracts with Vendors are governed by the terms of those contracts and
                                            applicable federal, state, provincial, and local laws, including laws
                                            pertaining to agreements with vendors. Should a Client have a dispute with a
                                            Vendor, it is their responsibility to address the dispute directly with the
                                            Vendor. Havuh is not a party or third-party beneficiary to the contracts
                                            between Clients and Vendors and plays no role in the resolution of such
                                            disputes.
                                        </p>
                                    </section>
                                    <section>
                                        <h3>8. Release:</h3>
                                        <p>
                                            8.1 By accepting the project provided by Havuh and receiving other valuable
                                            consideration, you hereby agree to forever release and discharge Havuh, its
                                            officers, directors, affiliates, parent and subsidiary companies, employees,
                                            and agents from any and all claims, demands, causes of action, losses, and
                                            damages (both direct and consequential) of every kind and nature, known and
                                            unknown, suspected and unsuspected, disclosed and undisclosed, arising out
                                            of or in any way relating to your agreement with Vendors and any work or
                                            services performed by Vendors. This release includes, but is not limited to,
                                            any disputes, grievances, or claims arising from the project management
                                            service and the interactions between you and the Vendors.
                                        </p>
                                        <p>
                                            8.2 If you are a resident of California, you specifically waive the
                                            provisions of California Civil Code Section 1542, which states: "A general
                                            release does not extend to claims that the creditor or releasing party does
                                            not know or suspect to exist in their favor at the time of executing the
                                            release, and that, if known by them, would have materially affected their
                                            settlement with the debtor or released party." By waiving this provision,
                                            you acknowledge that you have been made aware of this specific California
                                            Civil Code and choose to release Havuh, its officers, directors, affiliates,
                                            parent and subsidiary companies, employees, and agents from any and all
                                            claims, known or unknown, suspected or unsuspected, disclosed or
                                            undisclosed, to the fullest extent permitted by law.
                                        </p>
                                    </section>
                                    <section>
                                        <h3>9. Warranty</h3>
                                        <p>
                                            9.1 Havuh makes no representation regarding the suitability of the contents
                                            or services of the website for any particular purpose. Havuh does not
                                            promise that the website will be error-free, uninterrupted, or that it will
                                            provide specific results from the use of the website or any content, search,
                                            or link on it. The website and its content are delivered on an "as-is" and
                                            "as-available" basis. Havuh cannot ensure that any files you download from
                                            the website will be free of viruses, contamination, or destructive features.
                                            Havuh will not be held liable or responsible for any errors or omissions in
                                            the content of the website, for your actions or inactions in connection with
                                            the website, or for any damage to your computer or data or any other damage
                                            you may incur in connection with the website.
                                        </p>
                                        <p>
                                            9.2 Havuh specifically disclaims all warranties and conditions of any kind,
                                            whether express or implied, including all implied warranties and conditions
                                            of merchantability, fitness for a particular purpose, title, and
                                            non-infringement. Havuh makes no guarantees of any specific result from the
                                            use of this website. Your use of this website is at your own risk.
                                        </p>
                                        <p>
                                            9.3 It is important to note that Havuh does not provide any warranties
                                            regarding the website's performance, functionality, or suitability for your
                                            individual needs. You should exercise caution and take necessary precautions
                                            when using the website, including implementing security measures to protect
                                            your computer and data.
                                        </p>
                                    </section>
                                    <section>
                                        <h3>10. Limitation of Liability and Exclusions:</h3>
                                        <p>
                                            10.1 In no event will Havuh, its officers, directors, affiliates, parent or
                                            subsidiary companies, employees, and/or agents be liable for any damages
                                            whatsoever, including without limitation, direct, indirect, punitive,
                                            incidental, general, special, or consequential damages, arising out of or
                                            relating to (a) the use of, content in, delay or inability to use, or
                                            otherwise arising in connection with the website, or (b) the conduct of a
                                            user, vendor, or anyone else in connection with the use of the website. Such
                                            damages may include, but are not limited to, bodily injury, property damage,
                                            emotional distress, data breaches, and/or any other damages, whether based
                                            on contract, tort, strict liability, or otherwise, even if advised of the
                                            possibility of such damages. Havuh is not liable for any lost data resulting
                                            from the operation of the website. Havuh expressly disclaims any and all
                                            liability for the acts, omissions, and conduct of any third-party users,
                                            Havuh users, advertisers, and/or sponsors on the website, in connection with
                                            the website, or otherwise related to your use of the website. By using the
                                            website, you agree to take reasonable precautions in all interactions with
                                            other users of the website. To the extent that your state does not allow the
                                            limitation of certain types of damages, the above exclusion may not apply to
                                            you.
                                        </p>
                                        <p>
                                            10.2 Some states and/or jurisdictions do not allow the exclusion or
                                            limitation of direct, indirect, incidental, special, and/or consequential
                                            damages, so the above exclusion may not apply to you. The above exclusions
                                            of liability are not applicable to residents of New Jersey. With respect to
                                            residents of New Jersey, Havuh will not be liable for indirect, special, or
                                            consequential damages arising out of or relating to (a) the use of, content
                                            in, delay or inability to use, or otherwise arising in connection with this
                                            website, or (b) the conduct of a user, vendor, or anyone else in connection
                                            with the use of the website. This limitation of liability for residents of
                                            New Jersey applies unless the damages are proven to be the result of the
                                            negligent, reckless, or intentional acts or omissions of Havuh. In such
                                            cases, Havuh may only be liable in the amount and proportion that its acts
                                            or omissions caused such damages.
                                        </p>
                                    </section>
                                    <section>
                                        <h3>11. Information You Provide to Havuh:</h3>
                                        <p>
                                            11.1 When you use or register for an account on the Havuh website, you will
                                            be required to provide certain information about yourself and your service
                                            needs if you request services. Some of this information will be shared with
                                            Vendors, who will utilize it to respond to your inquiries. By providing this
                                            information to Havuh or submitting a service request, you are expressly
                                            consenting to have the details of your request sent to Vendors.
                                            Additionally, you consent to Havuh's use and display of anonymous data
                                            records compiled from the information collected from your service request,
                                            including your first name and the nature of the services you requested in
                                            your city. This information assists other vendors in evaluating and
                                            utilizing Havuh's services.
                                        </p>
                                        <p>
                                            11.2 If you submit a service request, Havuh will transmit identifying
                                            information such as your full name and contact telephone number to the
                                            Vendors you have selected to contact you. This information will be shared
                                            with the selected Vendors, even if you are listed on any applicable "Do Not
                                            Call" list, in order for Havuh to provide the services described on the
                                            website, service your account, address matters related to your account, or
                                            for other purposes reasonably associated with your service request and
                                            Havuh's business, including marketing-related emails. For complete details
                                            on Havuh's use of your information, please review our Privacy Policy. You
                                            agree to provide accurate, current, and truthful information. If you provide
                                            any information that is untrue, not current, or incomplete, or if Havuh has
                                            reasonable grounds to suspect such information is untrue, inaccurate, not
                                            current, or incomplete, Havuh reserves the right to restrict your current or
                                            future use of the Havuh services (or any portion thereof).
                                        </p>
                                        <p>
                                            11.3 You are responsible for maintaining the confidentiality of your account
                                            password and for all activities associated with your account. You agree to
                                            immediately notify us of any unauthorized use of your account.
                                        </p>
                                        <p>
                                            11.4 If you provide Havuh with feedback regarding the Havuh service or the
                                            website, including but not limited to flaws, errors, bugs, anomalies,
                                            problems, or suggestions ("Feedback"), such Feedback will become the
                                            property of Havuh, which will have the right to use the Feedback and related
                                            information in any manner Havuh deems appropriate.
                                        </p>
                                        <p>
                                            11.5 Modifications to Website: Havuh reserves the right to modify or
                                            discontinue the Website, either temporarily or permanently, at any time and
                                            without notice. You acknowledge and agree that Havuh shall not be liable to
                                            you or any third party for any modification, suspension, or discontinuance
                                            of the Website.
                                        </p>
                                        <p>
                                            11.6 Blocking of IP Addresses: To protect the integrity of the Website,
                                            Havuh may, at its sole discretion, block certain IP addresses from accessing
                                            the Website.
                                        </p>
                                        <p>
                                            11.7 Privacy: Your use of the Website is also subject to our Privacy Policy,
                                            which can be found at [insert link to privacy policy].
                                        </p>
                                        <p>
                                            11.8 Disclaimers: Havuh is not responsible for any incorrect or inaccurate
                                            content posted on the Website or in connection with the Website, whether
                                            caused by users of the Website or by any equipment or programming associated
                                            with or utilized in the Website. Havuh is not responsible for the conduct,
                                            whether online or offline, of any users of the Website, including Clients
                                            and Vendors. Havuh assumes no responsibility for any errors, omissions,
                                            interruptions, deletions, defects, delays in operation or transmission,
                                            communication line failures, theft, or destruction or unauthorized access to
                                            or alteration of user input.
                                        </p>
                                        <p>
                                            11.9 Havuh is not responsible for any problems or technical malfunction of
                                            any telephone network or lines, computer online systems, servers or
                                            providers, computer equipment, software, failure of email or players, or any
                                            combination thereof, including injury or damage to users or any other third
                                            party's computer related to or resulting from participating in or
                                            downloading materials in connection with the Website.
                                        </p>
                                        <p>
                                            11.10 Under no circumstances will Havuh or any of its affiliates,
                                            advertisers, promoters, or distribution partners be responsible for any loss
                                            or damage, including personal injury, death, or property damage, resulting
                                            from the use of the Havuh Website, any content posted on the Website or
                                            transmitted to users, or any interactions between users of the Website,
                                            whether online or offline.
                                        </p>
                                        <p>
                                            11.11 The Havuh Website is provided on an "AS-IS" basis, and Havuh expressly
                                            disclaims any warranty of fitness for a particular purpose or warranty of
                                            non-infringement. Havuh cannot guarantee and does not promise any specific
                                            results from the use of the Havuh Website.
                                        </p>
                                        <p>
                                            11.12 Please note that any advice posted on the Website is not intended to
                                            replace or substitute for professional financial, medical, legal, or other
                                            advice. Havuh makes no representations or warranties and disclaims any and
                                            all liability concerning any treatment, action, or effect on any person
                                            following the information offered or provided within or through the Website.
                                        </p>
                                        <p>
                                            11.13 Links: The Website may contain links to other websites or resources
                                            provided by third parties. Since Havuh has no control over such sites and
                                            resources, you acknowledge and agree that Havuh is not responsible for the
                                            availability of these external sites or resources and does not endorse or
                                            assume any responsibility for any content, advertising, products, or other
                                            materials on or available from such sites or resources. Furthermore, you
                                            acknowledge and agree that Havuh shall not be held responsible or liable,
                                            directly or indirectly, for any damage or loss caused or alleged to be
                                            caused by or in connection with the use of or reliance on any content,
                                            goods, or services available on or through any such site or resource.
                                        </p>
                                    </section>
                                    <section>
                                        <h3>12. Termination of Account: </h3>
                                        <p>
                                            12.1 This Agreement will remain in full force and effect as long as you use
                                            the Website and/or are a Consumer of Havuh. You have the option to terminate
                                            your account at any time, for any reason. To cancel your account, please
                                            send a notice of termination to Havuh LLC,via email notice of termination to
                                            support@havuh.com Upon termination, your account will be disabled within 14
                                            days. Havuh may terminate your account or this Agreement at any time, with
                                            or without cause, by sending notice to the email address you provided during
                                            account registration, or to any other email or physical address you later
                                            provided to Havuh. All decisions regarding the termination of accounts will
                                            be made at the sole discretion of Havuh. Havuh is not obligated to provide
                                            prior notice before terminating your account and may be prohibited from
                                            disclosing the reason for termination.
                                        </p>
                                    </section>
                                    <section>
                                        <h3>13. Disputes and Governing Law:</h3>
                                        <p>
                                            13.1 In the event of any dispute arising out of or related to this Agreement
                                            or the Website, you agree that such dispute will be governed by the laws of
                                            the State of Ohio, without regard to its conflict of law provisions. You
                                            further agree and consent to the exclusive jurisdiction and venue of the
                                            state and federal courts located in Cleveland, Ohio, for the resolution of
                                            any such dispute.
                                        </p>
                                    </section>
                                    <section>
                                        <h3>14. Indemnification: </h3>
                                        <p>
                                            14.1 As a Consumer, you agree to indemnify, defend, and hold harmless Havuh,
                                            its parents, subsidiaries, affiliates, officers, agents, and other partners
                                            and employees, from and against any loss, liability, claim, demand, or suit,
                                            including reasonable attorney's fees, resulting from or arising out of any
                                            act or omission on your part. It is agreed that any legal limitations
                                            affecting the validity and enforceability of your obligation under this
                                            Indemnification clause, now or in the future, are incorporated to the
                                            minimum extent necessary to comply with such limitations. As so modified,
                                            your obligation under this Indemnification clause will continue in full
                                            force and effect.
                                        </p>
                                    </section>
                                    <section>
                                        <h3>15. Survival: </h3>
                                        <p>
                                            15.1 The following provisions shall survive the abandonment, expiration, or
                                            termination of the Agreement: Agreement with Havuh, No Warranty or
                                            Endorsement, Interactions and Disputes with Vendors, Release, No Warranties,
                                            Limitations of Liability, all provisions in the Content on the Website
                                            section, Disclaimers, Disputes and Governing Law, Indemnification, No Third
                                            Party Beneficiaries, Survival, and any other representations, guarantees,
                                            warranties, or provisions that obligate you as a Consumer after the
                                            abandonment, expiration, or termination of this Agreement will be deemed to
                                            survive such abandonment, expiration, or termination.
                                        </p>
                                    </section>
                                    <section>
                                        <h3>16. Calls and Text Messages:</h3>
                                        <p>
                                            16.1 Consent to Receive Calls and Text Messages: By providing your telephone
                                            number(s) to Havuh, you consent to receive non-marketing calls and text
                                            messages from Havuh, its affiliates, service providers, agents, vendors,
                                            business partners, and third parties calling or texting on their behalf.
                                            This includes calls and text messages made with an autodialer or prerecorded
                                            voice, using the telephone number(s) you provide. You may also choose to
                                            provide consent to receive marketing calls and text messages made with an
                                            autodialer or prerecorded voice from Havuh and its affiliates, service
                                            providers, agents, vendors, business partners, and third parties. You have
                                            the right to opt-out of these communications at any time, and consent to
                                            receive marketing calls and text messages is not required as a condition of
                                            purchasing any products or services. You agree to promptly notify Havuh of
                                            any changes to the telephone number(s) you provide. Furthermore, you agree
                                            to indemnify Havuh for all claims, expenses, and damages related to or
                                            caused in whole or in part by your failure to notify Havuh of any changes to
                                            your telephone number. The frequency of messages may vary depending on
                                            various factors, such as customer demand and the number of projects, leads,
                                            or job offers a vendor may have at any given time. You also agree that Havuh
                                            may send texts between the hours of 12:00 am and 11:59 pm.
                                        </p>
                                        <p>
                                            16.2 How to Opt-In to SMS / Push Notification Program: To opt-in and receive
                                            text messages regarding Havuh's services, please follow the instructions
                                            provided during registration. You will receive a text message confirming
                                            your opt-in to the program.
                                        </p>
                                        <p>
                                            16.3 How to Opt-Out of SMS / Push Notification Program: To stop receiving
                                            text messages or notifications from Havuh email support@havuh.com. Following
                                            your request to unsubscribe, you may receive a confirmation message stating
                                            that you have been removed from the Havuh messaging program. After receiving
                                            the confirmation message, no additional text messages from Havuh will be
                                            sent unless you reactivate your subscription to the program. Please note
                                            that opting out of the Havuh message program will not affect any consent you
                                            may have provided to receive text messages from vendors.
                                        </p>
                                        <p>
                                            16.4 Eligibility and Your Mobile Telephone Number: You represent that you
                                            are the account holder for the mobile telephone number(s) you provide to
                                            opt-in to the text message program. It is your responsibility to promptly
                                            notify Havuh if you change your mobile telephone number. You can update your
                                            number in your account settings or contact customer service to inform them
                                            of the number change.
                                        </p>
                                        <p>
                                            16.5 Cost: While Havuh does not impose a separate fee for sending text
                                            messages, message and data rates may apply to each text message sent or
                                            received in connection with the program, as determined by your mobile
                                            telephone service rate plan. Please consult your mobile telephone carrier
                                            for pricing plans.
                                        </p>
                                        <p>
                                            16.6 Access or Delivery to Mobile Network is Not Guaranteed: Delivery of
                                            information and content to a mobile device may fail due to various
                                            circumstances or conditions. You understand and acknowledge that network
                                            services, including mobile network services, are beyond Havuh's control.
                                            Havuh is not responsible for delayed or undelivered text messages. Please
                                            note that not all mobile carriers are supported.
                                        </p>
                                        <p>16.7 Support/Help: To request more information, email support@havuh.com</p>
                                        <p>
                                            16.8 Termination of Text Messaging: Havuh may suspend or terminate your
                                            receipt of text messages if it believes you have breached these terms,
                                            conditions, or notices. Your receipt of text messages may also be terminated
                                            if your mobile telephone service is terminated or lapses. Havuh reserves the
                                            right to modify or discontinue, temporarily or permanently, all or any part
                                            of
                                        </p>
                                    </section>
                                    <section>
                                        <h3>17. Electronic Notices:</h3>
                                        <p>
                                            17.1 You may have the option to communicate with Havuh through various
                                            channels such as e-mail, postal mail, telephone, and our website. Havuh may
                                            issue notices using these channels, including sending e-mails to the address
                                            you provide or text messages to the number you provide. You agree that such
                                            notices will have legal effect and satisfy any requirement for written
                                            notices.
                                        </p>
                                        <p>
                                            17.2 You may have the right to withdraw your consent to receive certain
                                            electronic communications. In compliance with applicable laws, Havuh will
                                            provide paper copies upon request. To make such a request, please email
                                            support@havuh.com. Please note that if you withdraw your consent, Havuh
                                            reserves the right to terminate your use of its products or services.
                                        </p>
                                        <p>
                                            17.3 To receive, access, and retain the notices sent by Havuh via e-mail,
                                            you must have Internet access and a computer or device with a compatible web
                                            browser. Additionally, you will need software capable of viewing PDF files.
                                            Your device or computer must have the capability to print, download, and
                                            store e-mails and PDF files. To receive, access, and retain notices sent via
                                            text, you must have a cellular phone or similar device capable of receiving
                                            and retaining text messages. By accepting these terms, you confirm that you
                                            are able to receive, access, and retain the notices sent by Havuh. You can
                                            update your contact information by contacting customer service at
                                            support@havuh.com.
                                        </p>
                                    </section>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </main>
                <CardsFooter />
            </>
        );
    }
}

export default TermsAndConditions;
