import React from "react";

class ProcessSteps extends React.Component {
    constructor(props) {
        super(props);
        this.state = { activeStep: "" };

        this.handleStepClick = this.handleStepClick.bind(this);
    }

    handleStepClick(step) {
        this.setState((prevState, props) => {
            if (prevState.activeStep === step) {
                return { activeStep: "" };
            }

            return { activeStep: step };
        });
    }

    render() {
        return (
            <div className="box">
                <div className={`process-cards ${this.state.activeStep !== "" ? "is-active" : ""}`}>
                    <div
                        className={`process-card process-card_dark process-card--1 ${
                            this.state.activeStep === "1" ? "is-active" : ""
                        }`}
                    >
                        <h2
                            className="process-card__title process-card__title_dark no-select pointer"
                            onClick={() => this.handleStepClick("1")}
                        >
                            Your Clients Calls
                        </h2>
                        <div className="schedules">
                            <div className="schedules__time">
                                When a client calls or texts you with a problem around their home or looking for vendors
                                for a project they have in mind, think Havuh.
                            </div>
                        </div>
                    </div>
                    <div className={`process-card process-card--2 ${this.state.activeStep === "2" ? "is-active" : ""}`}>
                        <h2 className="process-card__title no-select pointer" onClick={() => this.handleStepClick("2")}>
                            Create a Project
                        </h2>
                        <div className="schedules">
                            <div className="schedules__time">
                                Open the app, go to "New Job," add your client's contact information, and create a
                                project.
                            </div>
                            <div className="schedules__info">
                                <p>
                                    <span>
                                        Projects represent what the client needs or what their problem is. Projects are
                                        made up of a name and details about what the client has asked for. Both name and
                                        details are shared with the assigned vendors.
                                    </span>
                                </p>

                                <p>
                                    <span>
                                        It's possible your client needs their kitchen painted and one of their toilets
                                        won't stop running. In this case, you'd create two projects and in the next
                                        step, assign vendors underneath each.
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={`process-card process-card--3 ${this.state.activeStep === "3" ? "is-active" : ""}`}>
                        <h2 className="process-card__title no-select pointer" onClick={() => this.handleStepClick("3")}>
                            Assign Vendors
                        </h2>
                        <div className="schedules">
                            <div className="schedules__time">
                                Assigning a vendor to a project creates a job for that vendor and notifies them.
                            </div>
                            <div className="schedules__info">
                                <p>
                                    <span>
                                        When the vendor receives the job, they can either accept or reject it. If a
                                        vendor rejects your job, it might make sense to assign an additional vendor to
                                        give your client more options.
                                    </span>
                                </p>

                                <p>
                                    <span>
                                        If a vendor you assign isn't registered yet, you'll be prompted to text them.
                                        When explained how Havuh works, no vendors have had a problem. The effort you as
                                        an agent put in to coordinating and managing their work is noticied and
                                        appreciated. By clicking to text the vendor, you also submit the job. If you
                                        want to assign more vendors, locate the client on your dashboard and click the
                                        button to assign additional vendors.
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={`process-card process-card--4 ${this.state.activeStep === "4" ? "is-active" : ""}`}>
                        <h2 className="process-card__title no-select pointer" onClick={() => this.handleStepClick("4")}>
                            Work Finishes
                        </h2>
                        <div className="schedules">
                            <div className="schedules__time">
                                When a vendor you've assign to one of your clients' projects accepts, does the work for
                                your client, and gets paid by the client, it's the vendors responsibility to close the
                                job you created.
                            </div>
                            <div className="schedules__info"></div>
                        </div>
                    </div>

                    <div
                        className={`process-card process-card_dark process-card--5 ${
                            this.state.activeStep === "5" ? "is-active" : ""
                        }`}
                    >
                        <h2
                            className="process-card__title process-card__title_dark no-select pointer"
                            onClick={() => this.handleStepClick("5")}
                        >
                            Vendor Pays You
                        </h2>
                        <div className="schedules">
                            <div className="schedules__time">
                                In order to close the job, the vendor must compensate you.
                            </div>
                            <div className="schedules__info">
                                <p>
                                    <span>
                                        The last step of the process, once the work is completed, is for the vendor to
                                        close the job in Havuh by clicking "Pay to Close" and paying by credit or debit
                                        card.
                                    </span>
                                </p>

                                <p>
                                    <span>
                                        Once Havuh receives the payment, a check is dispatched to your brokerage for
                                        you. The payment cadence is determined on a broker-by-broker basis. If you have
                                        any concerns, you can email us at support at havuh.com.
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProcessSteps;
