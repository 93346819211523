/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter";

const EarningsCalculator = () => {
    const [agentSales, setAgentSales] = useState("1,250,000");
    const [brokerSplit, setBrokerSplit] = useState(35);
    const [numberOfAgents, setNumberOfAgents] = useState(100);
    const [managementIncomeToSalesRatio, setManagementIncomeToSalesRatio] = useState(3.7);
    const [agentIncome, setAgentIncome] = useState();
    const [brokerageIncome, setBrokerageIncome] = useState();

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, []);

    useEffect(() => {
        const agentSalesSanitized = Number(agentSales.replace(/,/gi, ""));
        const managementIncomeToSalesRatioDec = managementIncomeToSalesRatio / 100;
        const brokerSplitDec = brokerSplit / 100;
        const havuhUserCommission = 0.04;

        const totalManagementIncome = agentSalesSanitized * managementIncomeToSalesRatioDec * havuhUserCommission;
        const agentSplitDec = (100 - brokerSplit) / 100;
        const agentAverageManagementIncome = totalManagementIncome * agentSplitDec;
        const formattedAgentAverageManagementIncome = "$".concat(
            Number(agentAverageManagementIncome).toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
        );
        setAgentIncome(formattedAgentAverageManagementIncome);

        const brokerManagementIncomeAnnual = totalManagementIncome * brokerSplitDec * numberOfAgents;
        const brokerManagementIncomeQuarterly = brokerManagementIncomeAnnual / 4;
        const formattedBrokerManagementIncomeQuarterly = "$".concat(
            Number(brokerManagementIncomeQuarterly).toLocaleString("en", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
        );
        setBrokerageIncome(formattedBrokerManagementIncomeQuarterly);
    }, [agentSales, brokerSplit, numberOfAgents, managementIncomeToSalesRatio]);

    const onChangeAgentSales = (e) => {
        const agentSalesFormatted = e.target.value.replace(/\D/g, "").replace(/,/gi, "");
        setAgentSales(agentSalesFormatted.split(/(?=(?:\d{3})+$)/).join(","));
    };

    return (
        <>
            <DemoNavbar />
            <main>
                <section className="section section-shaped section-lg">
                    <div className="shape shape-style-1 bg-gradient-default">
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                    </div>
                    <Container className="pt-lg-7">
                        <Row className="justify-content-center">
                            <h1 className="text-white">Earnings Calculator</h1>
                        </Row>
                    </Container>
                </section>
                <section>
                    <Container className="pt-lg-7 mt-3">
                        <Row className="justify-content-center">
                            <Col lg={{ size: "10", offset: "1" }}>
                                <div>
                                    <p>
                                        You can use this calculator to estimate your earnings as an agent and a
                                        brokerage. Feel free to modify the inputs to more closely match your brokerage.
                                    </p>
                                    <p>
                                        What we've seen so far is that agents, on average, will generate 3.7 percent of
                                        their annual sales in management compensation. Agents with large networks and
                                        more years in the industry will perform substantially better. We plan to update
                                        this calculator as more data comes in.
                                    </p>
                                </div>
                            </Col>
                            <Col lg={{ size: "10", offset: "1" }}>
                                <div id="calculator-results" className="block results">
                                    <div className="result result_left">
                                        <label className="result_title">Agent Average Management Income Per Year</label>

                                        <input
                                            type="text"
                                            disabled
                                            className="override_input large_text center_text"
                                            value={agentIncome}
                                        />
                                    </div>

                                    <div className="result result_right">
                                        <label className="result_title">Brokerage Management Income Per Quarter</label>
                                        <input
                                            type="text"
                                            disabled
                                            className="override_input large_text center_text"
                                            value={brokerageIncome}
                                        />
                                    </div>
                                </div>

                                <div id="calculator">
                                    <div className="calculator_field">
                                        <label>Number of Agents</label>
                                        <input
                                            type="number"
                                            min="1"
                                            max="1000"
                                            value={numberOfAgents}
                                            onChange={(e) => setNumberOfAgents(e.target.value)}
                                            className="calculator_input"
                                        />
                                    </div>
                                    <div className="calculator_field">
                                        <label>Broker Split (%)</label>
                                        <div className="slider_and_entry">
                                            {/* <input
                                                    id="broker_split_slider"
                                                    type="range"
                                                    min="0"
                                                    max="100"
                                                    value={agentSales}
                                                    onChange={(e) => setAgentSales(e.target.value)}
                                                /> */}
                                            <input
                                                type="number"
                                                min="0"
                                                max="100"
                                                value={brokerSplit}
                                                onChange={(e) => setBrokerSplit(e.target.value)}
                                                className="calculator_input"
                                            />
                                        </div>
                                    </div>

                                    <div className="calculator_field">
                                        <label>Average Agents Sales Per Year ($)</label>
                                        <input
                                            type="text"
                                            className="calculator_input"
                                            value={agentSales}
                                            onChange={(e) => onChangeAgentSales(e)}
                                        />
                                    </div>

                                    <div className="calculator_field">
                                        <label>Management Income to Sales Ratio (%)</label>
                                        <div className="slider_and_entry">
                                            {/* <input
                                                    id="ratio_slider"
                                                    type="range"
                                                    min="0"
                                                    max="100"
                                                    step=".1"
                                                    value="3.70"
                                                /> */}
                                            <input
                                                type="number"
                                                min="0"
                                                max="100"
                                                step=".1"
                                                value={managementIncomeToSalesRatio}
                                                onChange={(e) => setManagementIncomeToSalesRatio(e.target.value)}
                                                className="calculator_input"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </main>
            <CardsFooter />
        </>
    );
};

export default EarningsCalculator;
