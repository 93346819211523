/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

class Hero extends React.Component {
    state = {};
    render() {
        return (
            <>
                <div className="position-relative">
                    <section className="section section-hero section-shaped">
                        <div className="shape shape-style-1 shape-default">
                            <span className="span-150" />
                            <span className="span-50" />
                            <span className="span-50" />
                            <span className="span-75" />
                            <span className="span-100" />
                            <span className="span-75" />
                            <span className="span-50" />
                            <span className="span-100" />
                            <span className="span-50" />
                            <span className="span-100" />
                        </div>
                        <Container className="shape-container d-flex align-items-center py-lg">
                            <div className="col px-0">
                                <Row className="align-items-center justify-content-center">
                                    <Col className="text-center mb-sm-5" lg="5">
                                        {/* <img
                                            alt="..."
                                            className="img-fluid"
                                            src={require("../../assets/img/brand/candid-car-sales-logo-small.png")}
                                            style={{ width: "200px" }}
                                        /> */}
                                        <p className="text-white form-title mt-4">
                                            You go above & beyond.
                                            <br />
                                            It’s time to benefit.
                                        </p>
                                        <p className="text-white font-size-sm">
                                            Your clients rely on your expertise when it comes to their home repair and
                                            renovation projects. You’ve been going above and beyond for your clients
                                            because it makes you a good agent. Havuh redefines how you help your clients
                                            with their home projects while getting you compensated for the extra work
                                            you put in.
                                        </p>
                                        <a href="https://apps.apple.com/us/app/havuh/id6449650156">
                                            <Button className="btn-neutral btn-icon" color="default">
                                                <span className="btn-inner--icon">
                                                    <i className="fa fa-mobile mr-2" />
                                                </span>
                                                <span className="nav-link-inner--text ml-1">Download for iOS</span>
                                            </Button>
                                        </a>
                                    </Col>
                                    <Col
                                        lg={{ size: 5, offset: 1 }}
                                        className="hide-spin hero-image"
                                        style={{ textAlign: "center" }}
                                    >
                                        <img
                                            alt="..."
                                            src={require("assets/img/brand/dashboard-screenshot.png")}
                                            height="500px"
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        {/* SVG separator */}
                        <div className="separator separator-bottom separator-skew zindex-100">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon style={{ fill: "#f2f2f2" }} points="2560 0 2560 100 0 100" />
                            </svg>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}

export default Hero;
