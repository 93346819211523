/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// reactstrap components
import { Button, Card, CardImg, NavItem, NavLink, Nav, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

class CardsFooter extends React.Component {
    render() {
        return (
            <>
                <footer className="footer has-cards">
                    <Container className="container-lg"></Container>
                    <Container style={{ marginTop: "200px" }}>
                        <Row className="row-grid align-items-center my-md">
                            <Col md="5">
                                <h3 className="text-white font-weight-medium mb-2">Empowering agents and vendors.</h3>
                            </Col>
                            <Col className="mb-5 mb-md-0" md={{ size: "6", offset: "1" }}>
                                <Card className="card-lift--hover border-0" style={{ backgroundColor: "#fff0" }}>
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/brand/havuh-white-small.png")}
                                        style={{ width: "300px" }}
                                    />
                                </Card>
                            </Col>
                        </Row>
                        <hr />
                        <Row className="align-items-center justify-content-md-between">
                            <Col md="3">
                                <div className="copyright text-white">© {new Date().getFullYear()} Havuh LLC</div>
                            </Col>
                            <Col md="9">
                                <Nav className="nav-footer justify-content-end">
                                    <NavItem>
                                        <Link to="/earnings-calculator" className="text-white mr-3">
                                            Earnings Calculator
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link to="/privacy" className="text-white mr-3">
                                            Privacy Policy
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link to="/terms-conditions" className="text-white mr-3">
                                            Terms & Conditions
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link to="/contact-us" className="text-white mr-3">
                                            Contact Us
                                        </Link>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </>
        );
    }
}

export default CardsFooter;
