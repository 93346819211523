/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter";

class PrivacyPolicy extends React.Component {
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }
    render() {
        return (
            <>
                <DemoNavbar />
                <main ref="main">
                    <section className="section section-shaped section-lg">
                        <div className="shape shape-style-1 bg-gradient-default">
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                        </div>
                        <Container className="pt-lg-7">
                            <Row className="justify-content-center">
                                <h1 className="text-white">Privacy Policy</h1>
                            </Row>
                        </Container>
                    </section>
                    <section>
                        <Container className="pt-lg-7 mt-3">
                            <Row className="justify-content-center">
                                <Col lg="12">
                                    <section>
                                        <h3>Information we collect and how we use it</h3>
                                        <p>
                                            Information Collected Upon Using Havuh: When you use the Havuh app, our
                                            servers automatically record information that your device sends whenever you
                                            access the app ("Log Data"). This Log Data may include information such as
                                            your IP address, device type, the pages you visit, the search terms you use,
                                            and any advertisements or links you interact with. For most users accessing
                                            the app, the IP address will be different each time they log in. We use Log
                                            Data to monitor app usage and for technical administration purposes.
                                        </p>
                                        <p>
                                            Information Collected Upon Registration: To access certain restricted
                                            sections of the Havuh app, you will need to become a registered user and
                                            provide certain personally identifiable information to Havuh LLC. This
                                            occurs when you sign up for the Havuh service, choose to receive marketing
                                            materials and information, create a project, or create a profile to offer
                                            services through Havuh. Personally identifiable information we may collect
                                            in these instances includes your full name, email address, office address,
                                            MLS number, brokerage, office address, password, street address, telephone
                                            number, mobile phone number for text messages, uploaded photographs, and
                                            other information you choose to provide or include in your public profile.
                                            If you register as a vendor, create a profile, or quote on a project
                                            request, we may also request and store your credit card number and other
                                            personally identifiable information.
                                        </p>
                                        <p>
                                            Business Information: If you create a profile, you have the opportunity to
                                            provide additional information ("Business Information") such as your
                                            business name, logo or avatar, credentials and qualifications, year of
                                            establishment, as well as links to your personal website, if applicable. You
                                            can also upload a portfolio of your previous work. We may supplement or
                                            modify the Business Information to comply with our editorial policies or
                                            include information from your website, materials you provide to us, or
                                            publicly available sources. When you respond to a customer's service
                                            request, all of the Business Information is displayed to the customer.
                                            Providing additional information beyond the registration requirements is
                                            optional but may enhance your ability to market your services effectively
                                            and potentially win more jobs through the Havuh app.
                                        </p>{" "}
                                        <p>
                                            If you contact us by email through the Havuh app, we may keep a record of
                                            your contact information and correspondence and use that information to
                                            respond to your inquiry.
                                        </p>
                                        <p>
                                            Surveys: We may collect information when you participate in surveys
                                            conducted by us or our partner companies. This may include questionnaires,
                                            telephone interviews, discussion groups, and general information about the
                                            Havuh service.
                                        </p>
                                        <p>
                                            Anonymous Data: We may create Anonymous Data records by excluding
                                            information that could personally identify you (such as your full name) from
                                            the data collected from you, such as information from a service request.
                                            "Anonymous Data" refers to data that is not associated with or linked to
                                            your personal information and does not allow the identification of
                                            individual persons. We reserve the right to use and disclose Anonymous Data
                                            to third parties at our discretion.
                                        </p>
                                    </section>
                                    <section>
                                        <h3>Use of Information</h3>
                                        <p>In general, at Havuh, we may use the information we collect about you to:</p>
                                        <ul>
                                            <li>Fulfill your requested goods and services.</li>
                                            <li>Oversee your account and supply customer and technical support.</li>
                                            <li>
                                                Carry out studies and evaluations of your application utilization, as
                                                well as your interest in our offerings, services, or content, and those
                                                presented by others.
                                            </li>
                                            <li>
                                                Interact with you via email, traditional mail, phone calls, SMS, or
                                                portable devices.
                                            </li>
                                            <li>
                                                Reach out to you concerning uncompleted service inquiries or job
                                                proposals.
                                            </li>
                                            <li>Generate Anonymized Data sets from the gathered data.</li>
                                            <li>Confirm your eligibility and accordingly allocating funds.</li>
                                            <li>Administer our business activities.</li>
                                            <li>
                                                Carry out additional duties as explained to you at the time of data
                                                collection.
                                            </li>
                                            <li>
                                                Comply with and enforce applicable legal requirements, relevant industry
                                                standards and our policies, including this Privacy Notice and our Terms
                                                & Conditions.
                                            </li>
                                            <li>
                                                Furthermore, we may use your data for promotional activities, offering
                                                you details about our goods and services. If you decide to stop
                                                receiving such information or correspondences from us, kindly adhere to
                                                the opt-out guidelines provided in any of the messages.
                                            </li>
                                        </ul>
                                    </section>
                                    <section>
                                        <h3>Information Sharing and Disclosure</h3>
                                        <p>
                                            We want you to understand when and with whom we may share personal or other
                                            information we have collected about you or your activities via our App or
                                            other Applications. We do not share your personal information with others
                                            except as indicated below or when we inform you and give you an opportunity
                                            to opt out of having your personal information shared. Under no
                                            circumstances, other than in the event of a Business Transfer (as discussed
                                            below) will Havuh LLC sell, rent or lease its user lists (or the user list
                                            of any individual user) to a third party.
                                        </p>
                                        <h4>Circumstances under which we may share your personal information:</h4>
                                        <p>
                                            To Provide Information to Users: For the purpose of this Privacy Policy
                                            only, a “user” is defined as any entity or person that seeks to utilize the
                                            management services provided by Havuh, including clients and affiliates of
                                            registered users that may or may not have a contractual relationship with
                                            Havuh. We may share your personal information with users. The information we
                                            share is generally the information contained in the Project Creation Form,
                                            including the username (and first name of the Managing user when we have
                                            it), the type of work that is in need of management, when and where the
                                            service should be performed, a description of the work needed, and any
                                            photographs pertaining to the project. When you register to accept a price
                                            quote from a user or one of its clients or affiliates, additional
                                            information may be shared, including personally identifiable information
                                            such as your real name, phone number, email address, and street address of
                                            the job location.
                                        </p>
                                        <p>
                                            To Provide Information to Users: If you are a registered user, we may share
                                            you and your clients' and affiliates' personal information with other users
                                            requesting services through Havuh. Sharing this information with vendors
                                            helps them to assess user quality and select the user to perform the service
                                            they need done. The information we share is generally the information in
                                            your Profile, with the exception of your address, contact phone number,
                                            mobile phone number, and email address. In addition, we share the project
                                            information you submitted on the Project Creation and any additional
                                            information you choose to provide to the vendor.
                                        </p>
                                        <p>
                                            To Facilitate Havuh's Use of Third-Party Services: We engage certain trusted
                                            third parties to perform functions and provide services to us, including,
                                            without limitation, hosting and maintenance, security, database storage and
                                            management, application monitoring, direct marketing campaigns and user
                                            credit card information storage. We will share your personally identifiable
                                            information with these third parties, but only to the extent necessary to
                                            perform these functions and provide such services, and only pursuant to
                                            binding contractual obligations requiring such third parties to maintain the
                                            privacy and security of your data.
                                        </p>
                                    </section>
                                    <section>
                                        <h4>Changing or Deleting Information</h4>
                                        <p>
                                            If you are a registered user of an Application, you may access and update or
                                            correct the information you provided to us by emailing support at
                                            support@havuh.com with your request, which will be acted upon within 21
                                            days.
                                        </p>
                                    </section>
                                    <section>
                                        <h4>Security</h4>
                                        <p>
                                            The protection and confidentiality of your personal information is of utmost
                                            importance to Havuh. We use a combination of administrative, physical, and
                                            digital safeguards aimed at keeping your data secure from unauthorized
                                            access. Despite our best efforts, we cannot provide an absolute guarantee
                                            against the potential intrusion by hackers or unauthorized individuals. It's
                                            important to be aware that when using our Website or other Applications,
                                            your data may traverse infrastructures operated by third parties, which are
                                            beyond Havuh's direct control.
                                        </p>
                                    </section>
                                    <section>
                                        <h4>Links to Other Websites and Services</h4>
                                        <p>
                                            Our Applications provide links to various other websites. Please note that
                                            our provision of a link does not imply our endorsement, authorization, or
                                            any affiliation with that external party. We do not have authority over
                                            these third-party websites. These websites might deposit their own cookies
                                            or other files on your device, gather data, or request personally
                                            identifiable information from you. Different websites may have different
                                            practices regarding the use or disclosure of the personally identifiable
                                            information you provide to them. We recommend that you review the privacy
                                            policies or declarations of any other websites that you choose to visit.
                                        </p>
                                    </section>
                                    <section>
                                        <h4>Children</h4>
                                        <p>
                                            Our Applications are not intended for use by individuals below the age of
                                            18. If a parent or guardian discovers that their child has shared personally
                                            identifiable information with us without their consent, they are encouraged
                                            to reach out to us at support [at] Havuh.com. Havuh does not deliberately
                                            gather personally identifiable data from those under 18. Should we learn
                                            that such information has been provided by a minor under 18, we will make
                                            commercially reasonable efforts to expeditiously remove such information
                                            from our databases.
                                        </p>
                                    </section>
                                    <section>
                                        <p>
                                            If you have further inquiries or concerns about this Policy, or if you wish
                                            to exclude yourself from any particular provision, you are welcome to
                                            contact us at any time at support@havuh.com.
                                        </p>
                                    </section>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </main>
                <CardsFooter />
            </>
        );
    }
}

export default PrivacyPolicy;
