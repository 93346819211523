import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, Button } from "reactstrap";

const DemoNavbar = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <Navbar color="light" light expand="md">
            <NavbarBrand href="/">
                <img alt="..." src={require("../../assets/img/brand/havuh.png")} />
            </NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="mr-auto" navbar>
                    {isOpen && (
                        <>
                            <NavItem style={{ textAlign: "right" }}>
                                <Button onClick={toggle} className="btn-neutral btn-icon">
                                    <i className="fa fa-close mr-2 fa-lg" />
                                </Button>
                            </NavItem>
                            <NavItem>
                                <Link to="/earnings-calculator" className="text-black mr-3">
                                    Home
                                </Link>
                            </NavItem>
                        </>
                    )}
                    <NavItem>
                        <Link to="/earnings-calculator" className="text-black mr-3">
                            Earnings Calculator
                        </Link>
                    </NavItem>
                    {isOpen && (
                        <>
                            <NavItem>
                                <Link to="/privacy" className="text-black mr-3">
                                    Privacy Policy
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/terms-conditions" className="text-black mr-3">
                                    Terms & Conditions
                                </Link>
                            </NavItem>
                        </>
                    )}
                </Nav>
                <NavItem className="d-none d-lg-block ml-lg-4">
                    <a href="https://apps.apple.com/us/app/havuh/id6449650156">
                        <Button className="btn-neutral btn-icon" color="default">
                            <span className="btn-inner--icon">
                                <i className="fa fa-mobile mr-2" />
                            </span>
                            <span className="nav-link-inner--text ml-1">Get Havuh</span>
                        </Button>
                    </a>
                </NavItem>
            </Collapse>
        </Navbar>
    );
};

export default DemoNavbar;
