/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import ProcessSteps from "./ProcessSteps";

class HowItWorks extends React.Component {
    render() {
        return (
            <>
                <section className="section section-shaped">
                    <div className="shape shape-style-1 shape-dark">
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                    </div>
                    <Container style={{ marginBottom: 50 }}>
                        <Row className="row-grid justify-content-center">
                            <Col className="text-center" lg={{ size: 10 }}>
                                <h2 className="display-3" id="how-it-works" style={{ color: "#fff" }}>
                                    How Havuh Works
                                </h2>
                                <p className="lead" style={{ color: "#fff" }}>
                                    Click to expand each step below to learn more about the process
                                </p>

                                <ProcessSteps />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={{ size: 12 }} style={{ textAlign: "center", marginTop: "40px" }}>
                                <iframe
                                    className="video-player"
                                    src="https://www.youtube.com/embed/pSEmyllWUE0"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen
                                ></iframe>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </>
        );
    }
}

export default HowItWorks;
