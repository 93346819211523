import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/havuh.scss?v1.1.0";

import Index from "views/Index.js";
import PrivacyPolicy from "views/PrivacyPolicy.js";
import ContactUs from "views/ContactUs.js";
import TermsAndConditions from "views/TermsAndConditions";
import EarningsCalculator from "views/EarningsCalculator";

ReactDOM.render(
    <BrowserRouter>
        <div
            style={{ display: "grid", gridTemplateRows: "auto 1fr auto", gridAutoColumns: "100%", minHeight: "100svh" }}
        >
            <Switch>
                <Route path="/" exact render={(props) => <Index {...props} />} />
                <Route path="/privacy">
                    <PrivacyPolicy />
                </Route>
                <Route path="/privacy-policy">
                    <PrivacyPolicy />
                </Route>
                <Route path="/terms-conditions">
                    <TermsAndConditions />
                </Route>
                <Route path="/contact-us" exact render={(props) => <ContactUs {...props} />} />
                <Route path="/earnings-calculator" exact render={(props) => <EarningsCalculator {...props} />} />
                <Redirect to="/" />
            </Switch>
        </div>
    </BrowserRouter>,
    document.getElementById("root")
);
