import React, { useState } from "react";
import { Container, Row, Col, Tooltip } from "reactstrap";

const HowToGetStarted = () => {
    const [projectTooltipIsOpen, setProjectTooltipIsOpen] = useState(false);
    const [vendorTooltipIsOpen, setVendorTooltipIsOpen] = useState(false);

    const toggleProjectTooltip = () => setProjectTooltipIsOpen(!projectTooltipIsOpen);
    const toggleVendorTooltip = () => setVendorTooltipIsOpen(!vendorTooltipIsOpen);

    return (
        <Container className="mt-5">
            <Row>
                <Col className="text-center">
                    <h2 className="display-3" id="faq">
                        How to Get Started
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col lg={{ size: 10, offset: 1 }}>
                    <div className="get-started-item">
                        <div className="get-started-number">1</div>
                        <div className="get-started-text">Download the iOS app (Android coming soon).</div>
                    </div>
                    <div className="get-started-item">
                        <div className="get-started-number">2</div>
                        <div className="get-started-text">Click "Sign Up" and register as an agent or a vendor.</div>
                    </div>
                    <div className="get-started-item">
                        <div className="get-started-number">3</div>
                        <div className="get-started-text">
                            Now tap sign in and enter the email and password you just used on the registration page.
                        </div>
                    </div>
                    <div className="get-started-item">
                        <div className="get-started-number">4</div>
                        <div className="get-started-text">
                            When a client asks you for help with a home project, come back to the app and click "New
                            Job."
                        </div>
                    </div>{" "}
                    <div className="get-started-item">
                        <div className="get-started-number">5</div>
                        <div className="get-started-text">Enter your clients contact information.</div>
                    </div>{" "}
                    <div className="get-started-item">
                        <div className="get-started-number">6</div>
                        <div className="get-started-text">
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>Click "Next" and create a project to represent what the client needs.</div>
                                <div>
                                    <i className="fa fa-info mr-2" id="ProjectTooltip" style={{ padding: "3px" }} />
                                    <Tooltip
                                        placement="right"
                                        isOpen={projectTooltipIsOpen}
                                        target="ProjectTooltip"
                                        toggle={toggleProjectTooltip}
                                    >
                                        For instance, if the client needs a new roof, you might title the project "Roof
                                        Replacement" and in the details section you could provide vendors information
                                        about the size of the house, the type of tiles the client is interested in, when
                                        the client is looking to have the work done by, and anything else that will help
                                        the vendor understand the nature of the work.
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                    <div className="get-started-item">
                        <div className="get-started-number">7</div>
                        <div className="get-started-text">
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div>Click "Next" and assign vendors to this new project.</div>
                                <div>
                                    <i className="fa fa-info mr-2" id="VendorTooltip" style={{ padding: "3px" }} />
                                    <Tooltip
                                        placement="right"
                                        isOpen={vendorTooltipIsOpen}
                                        target="VendorTooltip"
                                        toggle={toggleVendorTooltip}
                                    >
                                        You can assign as many vendors as you'd like. Each vendor you assign will be
                                        notified of the job you sent and can then accept or reject it.
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>{" "}
                    <div className="get-started-item">
                        <div className="get-started-number">8</div>
                        <div className="get-started-text">
                            After you've tapped "Submit," you'll see the client on your dashboard. Tap their name to
                            expand details about the projects you've created and the jobs underneath.
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default HowToGetStarted;
