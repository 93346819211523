/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

class Description extends React.Component {
    render() {
        return (
            <>
                <section className="section section-shaped">
                    <div className="shape shape-style-1 shape-light">
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                        <span />
                    </div>
                    <Container className="py-md">
                        <Row className="justify-content-between align-items-center">
                            <Col className="mb-5 mb-lg-0" lg="5">
                                <p className="h5 text-primary mt-4">
                                    A client contacts you because they need a new roof or want to have their bathrooms
                                    repainted. Your client trusts you and wants your help finding reliable vendors.
                                    Without Havuh, you'd send your client your vendor list. But it never ends there. You
                                    end up helping in every conceivable way: you unlock the home for vendors, review
                                    quotes, coordinate materials deliveries, etc.
                                </p>
                                <p className="h5 text-primary mt-4">
                                    Havuh doesn't change what makes you a good agent. Havuh lets you send your client's
                                    contact information to the vendors you know and trust. Vendors receive this
                                    information and by agreeing to move forward, agree to pay you, the agent, a
                                    management fee for the time you always spend managing the client's home project.
                                </p>
                            </Col>
                            <Col className="mb-3" lg="6">
                                <div
                                    className="rounded shadow-lg overflow-hidden transform-perspective-right"
                                    style={{ textAlign: "center" }}
                                >
                                    <img
                                        alt="..."
                                        src={require("assets/img/brand/havuh-steps.png")}
                                        className="description-image"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    {/* SVG separator */}
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon style={{ fill: "#f2f2f2" }} points="2560 0 2560 100 0 100" />
                        </svg>
                    </div>
                </section>
            </>
        );
    }
}

export default Description;
